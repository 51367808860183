import { ref, onMounted, onUnmounted } from 'vue';
import { getKings } from "@/http";
export default {
  props: {
    symbol: {
      type: String,
      default: 'BTCUSD' // 默认交易对
    },
    interval: {
      type: String,
      default: '1m' // 默认时间间隔
    },
    color: {
      type: String,
      default: '#56F9AA'
    },
    itemPrice: {
      type: Object,
      default: []
    }
  },
  setup(props) {
    const btcCanvas = ref(null);
    const prices = ref([]);
    const fetchBTCPrice = async () => {
      try {
        // const response = await getKings({code:props.symbol,limit:30})
        const response = props.itemPrice
        /*   fetch(
         `https://api.binance.com/api/v3/klines?symbol=${props.symbol}&interval=${props.interval}&limit=30`
        )*/;
        const data = await response.list;
        // 提取收盘价并存储
        prices.value = data.map(item => parseFloat(item.price)); // item[4]是收盘价
        drawChart();
      } catch (error) {
        console.error('Error fetching BTC price:', error);
      }
    };
    const drawChart = () => {
      const canvas = btcCanvas.value;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布

      const width = canvas.width;
      const height = canvas.height;
      const padding = 5; // 为了适应44px的高度

      // 绘制价格线
      ctx.beginPath();
      const stepX = (width - 2 * padding) / (prices.value.length - 1);
      const minPrice = Math.min(...prices.value);
      const maxPrice = Math.max(...prices.value);

      // 避免在没有价格数据时绘制
      if (prices.value.length > 0) {
        prices.value.forEach((price, index) => {
          const x = padding + index * stepX;
          const y = height - padding - (price - minPrice) / (maxPrice - minPrice) * (height - 2 * padding);
          if (index === 0) {
            ctx.moveTo(x, y); // 移动到起点
          } else {
            ctx.lineTo(x, y);
          }
        });
        ctx.strokeStyle = props.color;
        ctx.stroke();
      }
    };
    let intervalId = null;
    onMounted(() => {
      // 初始加载价格
      fetchBTCPrice();
      // 每分钟获取一次价格
      intervalId = setInterval(fetchBTCPrice, 10000); // 10秒
      // 清理定时器
      return () => clearInterval(intervalId);
    });
    onUnmounted(() => {
      clearInterval(intervalId);
    });
    return {
      btcCanvas,
      prices
    };
  }
};